
<template>
    <div class="stepsdemo-content">
        <Card class="py-10">
            <template v-slot:title>
              <h2 class="text-center mx-auto w-full lg:w-3/4 xl:w-1/2">
                Buscar Accionante
              </h2>
            </template>
            <template v-slot:content>
              <div class="p-2 mx-auto w-full lg:w-3/4 xl:w-1/2 flex justify-center items-center gap-4">
                <div class="p-fluid">
                  <div class="field mb-4">
                    <Dropdown
                      id="docTipoId"
                      v-model="modelo.docTipoId"
                      :options="tiposDocumentos"
                      option-label="nombre"
                      option-value="code"
                      :filter="true"
                      placeholder="TIPO DE DOCUMENTO"
                      style="width: 18.75rem"
                    />
                    <MessageError :text="errors.docTipoId"/>
                  </div>
                  <div class="field grid">
                    <InputText
                      id="numeroIdentificacion"
                      v-model="modelo.numeroIdentificacion"
                      placeholder="NÚMERO DE DOCUMENTO"
                      style="width: 18.75rem"
                    />
                    <MessageError :text="errors.numeroIdentificacion"/>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:footer>
                <div class="text-center mx-auto w-full lg:w-3/4 xl:w-1/2">
                    <i></i>
                    <Button label="Consultar" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import DocTipoService from '@/apps/pharmasan/juridica/procesosJuridicos/services/docTipo.service'
import { onMounted, ref } from 'vue'
import PersonaNaturalService from '@/apps/pharmasan/juridica/procesosJuridicos/services/personaNatural.service'
import { useForm, useField } from 'vee-validate'
import * as yup from 'yup'
import storeTutela from '@/apps/pharmasan/juridica/procesosJuridicos/store/tutela.store'
import { useRouter } from 'vue-router'
export default {
    name: 'BuscarAccionante',
    setup () {
    /* instancias */
    const _DocTipoService = new DocTipoService()
    const _PersonaNaturalService = new PersonaNaturalService()
    const submitted = ref(false)
    const setAccionante = ref()
    const router = useRouter()
    /** schema **/
    const schema = yup.object({
      docTipoId: yup.string().required('El campo es requerido').label(''),
      numeroIdentificacion: yup.string().required('El campo es requerido').label('')
    })
    const { errors, values: modelo, handleSubmit } = useForm({
      validationSchema: schema
    })
    useField('docTipoId')
    useField('numeroIdentificacion')
    /* data */
    const tiposDocumentos = ref([])
    /* methods */
    const nextPage = handleSubmit((values) => {
      if (!values) return
      _PersonaNaturalService.searchByDocument(values).then(async ({ data }) => {
        await storeTutela.commit('setAccionante', data)
        setTimeout(() => {
          router.push('datos-accionante', { pageIndex: 0 })
        }, 500)
      })
    })
    onMounted(() => {
      _DocTipoService.getAll().then(({ data }) => {
        tiposDocumentos.value = data
      })
    })
    return {
      nextPage,
      tiposDocumentos,
      errors,
      modelo,
      submitted,
      setAccionante
    }
  }

}
</script>
